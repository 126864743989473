import React from 'react';
import './privacyPolicy.scss';
import Footer from "../components/Footer/Footer";
import Heading from '../components/Heading/Heading';

export default function PrivacyPolicy() {

    return (
    <div>
        <Heading/>
        <div className="ContainerPP">
            <div className="ContentPP" >
                <div className="HeadingPrivacyPolicy">
                    Datenschutz&shy;erklärung
                </div>
                <div className="TextPrivacyPolicy">
                    Wir freuen uns über Ihr Interesse an unserem Unternehmen und unseren Produkten bzw. Dienstleistungen und möchten, 
                    dass Sie sich beim Besuch unserer Internetseiten auch hinsichtlich des Schutzes Ihrer personenbezogenen Daten sicher fühlen.         
                    <br></br><br></br>
                    Wir nehmen den Schutz Ihrer personenbezogener Daten sehr ernst. Die Beachtung der gesetzlichen
                    Bestimmungen ist für uns eine Selbstverständlichkeit.
                    <br></br><br></br>
                    Auf dieser Webseite wird Matomo zur Webanalyse benutzt, ein Dienst der InnoCraft Ltd., 150 Willis St, 6011 Wellington, New Zealand, NZBN 6106769. 
                    Der Schutz Ihrer Daten ist uns wichtig, deshalb haben wir Matomo zusätzlich so konfiguriert, dass Ihre IP-Adresse ausschließlich gekürzt erfasst wird. 
                    Wir verarbeiten Ihre personenbezogenen Nutzungsdaten daher anonymisiert. 
                    Ein Rückschluss auf Ihre Person ist uns nicht möglich. 
                    Weitere Informationen zu den Nutzungsbedingungen von Matomo und den datenschutzrechtlichen Regelungen finden Sie unter:&nbsp; 
                    https://matomo.org/privacy/
                    <br></br><br></br>
                    Es wird ein temporärer Session-Cookie (MATOMO_SESSID) angelegt, 
                    um die Einstellungen des Opt-Out zu speichern – dieser wird nicht zum Tracken verwendet. 
                    Die durch diesen Cookie erzeugten Informationen werden nicht an Dritte weitergegeben und verletzen nicht Ihre Privatsphäre.
                </div>
                <div className="SubHeadingPrivacyPolicy">
                    Name oder Firma der verantwortlichen Stelle
                </div>
                <div className="CompanyPrivacyPolicy">
                    <div>akquinet tech@spree GmbH</div>
                    <div>Handelsregister: Berlin (Charlottenburg)</div>
                    <div>Umsatzsteuer-Identifikationsnummer: DE225964680</div>
                </div>
                <div className="SubHeadingPrivacyPolicy" style={{paddingTop:"2rem"}}>
                    Leiter der verantwortlichen Stelle und der Datenverarbeitung
                </div>
                <div className="SubSubHeading">
                    Geschäftsführer:
                </div>
                <div className="RepresentativesPrivacyPolicy">
                    <ul>
                        <li>Heinz Wilming</li>
                        <li>Dr. Torsten Fink</li>
                    </ul>
                </div>
                <div className="SubSubHeading">IT-Verantwortlicher:</div>
                <ul>
                    <li>Dr. Torsten Fink</li>
                </ul>

                <div className="SubHeadingPrivacyPolicy" style={{paddingTop:"5%"}}>
                    Anschrift der verantwortlichen Stelle
                </div>
                <div className="CompanyAdressPrivacyPolicy">
                    <div>akquinet tech@Spree</div>
                    <div>Bülowstraße 66</div>
                    <div>10783 Berlin</div>
                    <div>Telefon +49 30 2355 20-0</div>
                </div>   
            </div> 
        </div>
        <Footer/>
    </div>
    );
}